


































































































































import { Component, Prop, Vue, Ref } from 'vue-property-decorator'
import { Collection, PaginatedQueryResult } from '@/models'
import FuzzySearch from 'fuzzy-search'

import ComponentCreateDialog from '@/components/ComponentCreateDialog.vue'
import gql from 'graphql-tag'
import { CollectionCreateFragment } from '@/components/componentTypes/collections/fragments'
import Loading from '@/components/Loading.vue'

@Component({
  components: {
    Loading,
    ComponentCreateDialog
  },
  apollo: {
    collections: {
      query: gql`
        query getCollections($environmentId: ID) {
          collections(environmentId: $environmentId) {
            items {
              ...CollectionCreate
            }
          }
        }
        ${CollectionCreateFragment}
      `,
      variables() {
        return {
          environmentId: this.environmentId
        }
      }
    }
  }
})
export default class AdminCollections extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string

  drawer: boolean = true

  searchQuery = ''
  sortBy = 'name'
  createModalOpen = false

  get showDrawer() {
    if (this.$vuetify.breakpoint.lgAndUp) {
      this.drawer = true
      return true
    } else {
      this.drawer = false
      return false
    }
  }

  collections: Partial<PaginatedQueryResult<Partial<Collection>>> = {
    items: []
  }

  async handleCreate(collection?: Collection) {
    await this.$apollo.queries.collections.refetch()
    if (collection && collection._id) {
      return this.navigateToCollection(collection._id)
    }
  }

  navigateToCollection(collectionId: string) {
    return this.$router.push({
      name: 'adminCollectionEdit',
      params: {
        environmentId: this.environmentId,
        collectionId
      }
    })
  }

  get filteredCollections() {
    const searcher = new FuzzySearch(this.collections.items || [], [
      'name',
      '_id'
    ])
    return searcher.search(this.searchQuery)
  }

  transformResult(col: Partial<Collection>) {
    return {
      ...col,
      title: col.name,
      subtitle: col._id,
      href: {
        name: 'adminCollectionEdit',
        params: {
          environmentId: this.environmentId,
          collectionId: col._id
        }
      }
    }
  }

  toggleCreateModal(value: any) {
    this.createModalOpen =
      value != null && typeof value === 'boolean'
        ? value
        : !this.createModalOpen
  }
}
